// Log chat event to banalytics
function logEvent(event) {
    if (typeof window.bananas !== 'undefined') {
        window.bananas('event', `chat.${event}`);
    }
}

/*
* Create a custom <style> tag with keuze specific styles for the widget
* The <style> tag is appended to the <body> after(!) the default <style> tag for the Trengo widget
*/
function appendCustomStyle() {
    const widget = document.querySelector('#trengo-web-widget');
    if (widget) {
        const newScript = document.createElement('style');
        const content = document.createTextNode('.TrengoWidgetPanel__iframe { border-radius:20px !important; border: 1px solid #C4E3ED; box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;}');
        newScript.appendChild(content);
        document.body.appendChild(newScript);
    }
}

/*
 * Save chat status to automatically continue chat when navigating to different page/refresh
 * @param {Number} active - (0 or 1) The user has opened AND interacted with chat
 * @param {Number} open - (0 or 1) The chat is/should be toggled open
 */
function setSessionStatus(active = null, open = null) {
    let status = {};
    if (window.sessionStorage.getItem('keuze-chat')) {
        status = JSON.parse(window.sessionStorage.getItem('keuze-chat'));
    } else {
        status = {
            active: 0,
            open: 0,
        };
    }
    status.active = active !== null ? active : status.active;
    status.open = open !== null ? open : status.open;

    window.sessionStorage.setItem('keuze-chat', JSON.stringify(status));
}

// Add logging of events + store status as sessionStorage object
function addListeners() {
    window.Trengo.on_open = () => {
        setSessionStatus(null, 1);
        logEvent('open');
    };

    window.Trengo.on_close = () => {
        setSessionStatus(null, 0);
        logEvent('close');
    };

    window.Trengo.on_chat_started = () => {
        setSessionStatus(1, null);
        logEvent('started');
    };
    window.Trengo.on_chat_ended = () => {
        setSessionStatus(0, 0);
        logEvent('end');
    };
}

// Load chat widget
function loadChat() {
    // Set the api key
    window.Trengo = window.Trengo || {};
    window.Trengo.key = '0QMB4sRUJjyoEPiXQJkM';

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = false;
    script.src = 'https://static.widget.trengo.eu/embed.js';
    document.getElementsByTagName('head')[0].appendChild(script);

    // Return a promise so we can check when its finished loading
    return new Promise((resolve) => {
        window.Trengo.on_ready = () => {
            addListeners();
            appendCustomStyle();
            resolve(true);
        };
    });
}

function animatedLoader(showHide, chatComponent) {
    if (showHide === 'show') {
        const animatedSvg = '<span style="position:relative;background:transparent;width:33px;height:33px;z-index:100;display: flex;align-items: center;">'
            + '<svg width="33" height="10" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#ffa600">\n'
            + '    <circle cx="15" cy="15" r="15">\n'
            + '        <animate attributeName="r" from="15" to="15"\n'
            + '                 begin="0s" dur="0.8s"\n'
            + '                 values="15;9;15" calcMode="linear"\n'
            + '                 repeatCount="indefinite" />\n'
            + '        <animate attributeName="fill-opacity" from="1" to="1"\n'
            + '                 begin="0s" dur="0.8s"\n'
            + '                 values="1;.5;1" calcMode="linear"\n'
            + '                 repeatCount="indefinite" />\n'
            + '    </circle>\n'
            + '    <circle cx="60" cy="15" r="9" fill-opacity="0.3">\n'
            + '        <animate attributeName="r" from="9" to="9"\n'
            + '                 begin="0s" dur="0.8s"\n'
            + '                 values="9;15;9" calcMode="linear"\n'
            + '                 repeatCount="indefinite" />\n'
            + '        <animate attributeName="fill-opacity" from="0.5" to="0.5"\n'
            + '                 begin="0s" dur="0.8s"\n'
            + '                 values=".5;1;.5" calcMode="linear"\n'
            + '                 repeatCount="indefinite" />\n'
            + '    </circle>\n'
            + '    <circle cx="105" cy="15" r="15">\n'
            + '        <animate attributeName="r" from="15" to="15"\n'
            + '                 begin="0s" dur="0.8s"\n'
            + '                 values="15;9;15" calcMode="linear"\n'
            + '                 repeatCount="indefinite" />\n'
            + '        <animate attributeName="fill-opacity" from="1" to="1"\n'
            + '                 begin="0s" dur="0.8s"\n'
            + '                 values="1;.5;1" calcMode="linear"\n'
            + '                 repeatCount="indefinite" />\n'
            + '    </circle>\n'
            + '</svg>'
            + '</span>';
        const animatedNode = document.createElement('span');
        chatComponent.style.position = 'relative';
        chatComponent.style.overflow = 'hidden';
        animatedNode.setAttribute('style', 'width: 100%;height: 100%;position: absolute;justify-content: center;align-items: center;display: flex;background: transparent;');
        animatedNode.classList.add('chat_loader');
        animatedNode.innerHTML = animatedSvg;
        // Hide the chat logo and text
        chatComponent.querySelectorAll(':scope > *')
            .forEach((element) => {
                element.style.opacity = 0;
            });
        chatComponent.appendChild(animatedNode);
    } else {
        const loaderComponents = document.querySelectorAll('.chat_loader');
        loaderComponents.forEach((loaderComponent) => {
            loaderComponent.remove();
        });
        // Show the chat logo and text
        chatComponent.querySelectorAll(':scope > *')
            .forEach((element) => {
                element.style.opacity = 1;
            });
    }
}

// If the user is currently using chat, reopen chat on page load
export function tryResumeChat() {
    if (window.sessionStorage.getItem('keuze-chat') === null) {
        return;
    }
    const status = JSON.parse(window.sessionStorage.getItem('keuze-chat'));
    if (status.active) {
        loadChat()
            .then(() => {
                if (status.open) {
                    window.Trengo.Api.Widget.open('chat');
                }
            });
    }
}

export function openChat(element = null) {
    // If chat is already loaded, open chat
    if (window.Trengo && document.getElementById('trengo-web-widget')) {
        window.Trengo.Api.Widget.open('chat');
    } else {
        if (element) {
            element.style.pointerEvents = 'none';
            animatedLoader('show', element);
        }
        // Edgecase for headlessRendere when window object exists but chat is not rendered
        if (document.getElementById('trengo-web-widget')) {
            delete window.Trengo;
        }
        loadChat()
            .then(() => {
                window.Trengo.Api.Widget.open('chat');

                // Timout to offset rendering
                setTimeout(() => {
                    if (element) {
                        animatedLoader('hide', element);
                        element.style.pointerEvents = null;
                    }
                }, 300);
            });
    }
}
